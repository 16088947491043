define("labs-regional-viz/utils/get-popup-sql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPopupSQL;

  function getPopupSQL() {
    var lngLat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      lng: 0,
      lat: 0
    };
    var mapConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      popupColumns: []
    };
    var geographyLevel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'municipality';
    var lng = lngLat.lng,
        lat = lngLat.lat;
    var popupColumns = mapConfig.popupColumns;

    function getPopupValue(geomType) {
      var selectChunks = [];
      popupColumns.forEach(function (columnConfig) {
        var match = columnConfig.values.find(function (d) {
          return d.geomType === geomType;
        });
        if (match) selectChunks.push("".concat(match.columnName, " AS ").concat(columnConfig.id, ", ").concat(match.cv, " AS ").concat(columnConfig.id, "_cv, ").concat(match.sig, " AS ").concat(columnConfig.id, "_sig"));
      });
      if (selectChunks.length === 0) return '';
      return ",".concat(selectChunks.join(',')); // prepend comma
    }

    var SQLArray = [];
    SQLArray.push("\n    SELECT 'region' as geomtype, null as islitown, null as ishouperm, null as iscommap, null as iscomnycwork, null as iscomnycres, name as name ".concat(getPopupValue('region'), "\n    FROM region_region_v220615\n  "));

    if (getPopupValue('subregion')) {
      SQLArray.push("\n      SELECT 'subregion' as geomtype, null as islitown, null as ishouperm, null as iscommap, null as iscomwork, null as iscomres, name as name ".concat(getPopupValue('subregion'), "\n      FROM region_subregion_v220615\n      WHERE ST_Intersects(the_geom, ST_SetSRID(ST_Point(").concat(lng, ", ").concat(lat, "), 4326))\n    "));
    }

    if (geographyLevel === 'subregion') return SQLArray.reverse().join(' UNION ALL ');

    if (getPopupValue('county')) {
      SQLArray.push("\n      SELECT 'county' as geomtype, null as islitown, null as ishouperm, iscommap, iscomwork, iscomres, name as name ".concat(getPopupValue('county'), "\n      FROM region_county_v220615\n      WHERE ST_Intersects(the_geom, ST_SetSRID(ST_Point(").concat(lng, ", ").concat(lat, "), 4326))\n    "));
    }

    if (geographyLevel === 'county') return SQLArray.reverse().join(' UNION ALL ');

    if (getPopupValue('municipality')) {
      SQLArray.push("\n      SELECT 'municipality' as geomtype, islitown, ishouperm, null as iscommap, null as iscomwork, null as iscomres, namelsad as name ".concat(getPopupValue('municipality'), "\n      FROM region_municipality_v220615\n      WHERE ST_Intersects(the_geom, ST_SetSRID(ST_Point(").concat(lng, ", ").concat(lat, "), 4326))\n    "));
    }

    return SQLArray.reverse().join(' UNION ALL ');
  }
});