define("labs-regional-viz/utils/build-popup-content", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildPopupContent;

  function buildPopupContent(data, geographyLevel, popupColumns, isPermitMap, isComNycWork, isComNycRes, isPercent, isRatio, isDollar, isChangeMeasurement) {
    var reliabilityDisclaimer = '<p class="popup-footer">Grayed values are not statistically reliable.</p>';
    var popupFooter = ''; // create rows for the table body

    var rowStrings = data.map(function (rowData) {
      var isHighlighted = geographyLevel === rowData.geomtype ? 'highlighted' : '';
      var columnTitles = popupColumns.map(function (d) {
        return d.id;
      });
      var columns = columnTitles.map(function (id) {
        var value = rowData[id];
        var isLarge = popupColumns.find(function (d) {
          return d.id === id;
        }).large;

        var _popupColumns$find = popupColumns.find(function (d) {
          return d.id === id;
        }),
            isMOE = _popupColumns$find.isMOE;

        var formattedValue = 'N/A';

        if (value !== null) {
          formattedValue = (0, _numeral.default)(value).format('0,0');
          if (value >= 10000) formattedValue = (0, _numeral.default)(value).format('0.0a');
          if (isPercent) formattedValue = (0, _numeral.default)(value).format('0,0%');
          if (isDollar) formattedValue = "$".concat((0, _numeral.default)(value).format('0,0'));
          if (isRatio) formattedValue = (0, _numeral.default)(value).format('0.00');
          if (isChangeMeasurement) formattedValue = (0, _numeral.default)(value).format('+0,0');
          if (isMOE) formattedValue = "\xB1".concat((0, _numeral.default)(value).format('0,0a'));
          if (isPercent && isChangeMeasurement) formattedValue = (0, _numeral.default)(value).format('+0.0%');
          if (isRatio && isChangeMeasurement) formattedValue = (0, _numeral.default)(value).format('+0.00');
          if (isChangeMeasurement && isMOE) formattedValue = "\xB1".concat((0, _numeral.default)(value).format('0,0'));
          if (isMOE && isPercent) formattedValue = "\xB1".concat((0, _numeral.default)(value).format('0.0%'));
        }

        var isInsignificant = false;

        if (rowData["".concat(id, "_cv")] >= 20 && rowData["".concat(id, "_cv")] !== null || rowData["".concat(id, "_sig")] <= 1.645 && rowData["".concat(id, "_sig")] !== null) {
          isInsignificant = true;
          popupFooter = reliabilityDisclaimer;
        }

        return "\n        <td class=\"".concat(isLarge ? 'large' : '', " ").concat(isInsignificant ? 'medium-gray' : '', " text-right\">\n          ").concat(formattedValue, "\n        </td>\n      ");
      }).join(''); // If this is a Permit Map

      if (isPermitMap) {
        // hide the rows that are Long Island municipalities that do not report housing permit data
        if (rowData.ishouperm === 0) {
          return '';
        }
      } else if (rowData.islitown === 1) {
        // If it's not a Permit Map, always hide the Long Island town rows
        return '';
      }

      if (isComNycWork) {
        // If this is a NYC Workers county commuting map (isComNycWork===1) indicated in yaml
        if (rowData.iscomwork === 0) {
          // hide the individual county rows that are instead shown as part of combined geographies
          return '';
        }
      } else if (isComNycRes) {
        // If this is a NYC Residents county commuting map (isComNycRes===1) indicated in yaml
        if (rowData.iscomres === 0) {
          // hide the individual county rows that are instead shown as part of combined geographies
          return '';
        }
      } else if (rowData.iscommap === 1) {
        // If this isn't a commuting map (neither isComNycRes nor isComNycWork)
        // hide the combined geography rows (rows where rowData.iscommap === 1)
        return '';
      }

      return "\n      <tr class=\"".concat(isHighlighted, "\">\n        <td class=\"geom\">\n          <small class=\"geom-type\">").concat(rowData.geomtype, "</small>\n          <span class=\"geom-name\">").concat(rowData.name, "</span>\n        </td>\n        ").concat(columns, "\n      </tr>\n    ");
    });
    rowStrings = rowStrings.join(''); // create table header cells

    var headerCells = popupColumns.map(function (d) {
      return "<td class=\"text-right\">".concat(d.title, "</td>");
    }).join('');
    return "\n    <table class=\"popup-table\">\n      <thead><td></td>".concat(headerCells, "</thead>\n      <tbody>").concat(rowStrings, "</tbody>\n    </table>\n    ").concat(popupFooter, "\n  ");
  }
});