define("labs-regional-viz/utils/structure-and-order-cms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = structureAndOrderCms;

  function structureAndOrderCms(json) {
    var mutatedJson = json;
    var _mutatedJson$meta$ord = mutatedJson.meta.orderings,
        orderings = _mutatedJson$meta$ord === void 0 ? {} : _mutatedJson$meta$ord; // turn everything except meta into arrays of objects

    var keys = Object.keys(mutatedJson).filter(function (key) {
      return key !== 'meta';
    });
    keys.forEach(function (key) {
      var collection = Object.values(mutatedJson[key]); // check for ordering and apply it

      if (orderings[key]) {
        orderings[key].forEach(function (ordering, index) {
          var id = ordering.id;
          var mutatedItem = collection.findBy('slug', id);

          if (mutatedItem) {
            Ember.set(mutatedItem, 'position', index);
            Ember.merge(mutatedItem, ordering);
          }
        });
      }

      mutatedJson[key] = collection.sortBy('position');
    });
    return mutatedJson;
  }
});