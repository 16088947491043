define("labs-regional-viz/templates/components/legend-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REAS1o6k",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"legend\"],[9],[0,\"\\n    \"],[4,\"if\",[[23,[\"narrativeVisible\"]]],null,{\"statements\":[[7,\"h5\"],[11,\"class\",\"legend-title\"],[9],[1,[23,[\"currentLayerGroup\",\"title\"]],false],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[23,[\"currentLayerGroup\",\"legend-title\"]]],null,{\"statements\":[[7,\"h5\"],[11,\"class\",\"legend-subtitle\"],[9],[1,[23,[\"currentLayerGroup\",\"legend-title\"]],false],[10]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"rows\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"legend-item\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"legend-color\"],[12,\"style\",[28,[\"color:\",[22,1,[\"color\"]],\";\"]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"type\"]],\"circle\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"fa fa-circle\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"fa fa-square\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n        \"],[1,[22,1,[\"label\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-regional-viz/templates/components/legend-box.hbs"
    }
  });

  _exports.default = _default;
});