define("labs-regional-viz/initializers/zf-widget", ["exports", "ember-cli-foundation-6-sass/initializers/zf-widget"], function (_exports, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _zfWidget.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _zfWidget.initialize;
    }
  });
});