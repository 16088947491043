define("labs-regional-viz/templates/components/geometry-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zj9Wx93K",
    "block": "{\"symbols\":[\"layerGroup\",\"&default\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"menu geography-selector\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"layerGroups\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[28,[[27,\"if\",[[27,\"eq\",[[23,[\"geographyLevel\"]],[22,1,[\"id\"]]],null],\"active\"],null],\" explode-block toggle-list-item\"]]],[9],[0,\"\\n      \"],[7,\"a\"],[9],[0,\"\\n        \"],[1,[27,\"capitalize\",[[22,1,[\"id\"]]],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],[23,[\"handleGeographyLevelToggle\"]],[22,1,[\"id\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-regional-viz/templates/components/geometry-toggle.hbs"
    }
  });

  _exports.default = _default;
});