define("labs-regional-viz/components/mapbox-gl-source", ["exports", "ember-mapbox-gl/components/mapbox-gl-source"], function (_exports, _mapboxGlSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mapboxGlSource.default;
    }
  });
});