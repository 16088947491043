define("labs-regional-viz/components/zf-callout", ["exports", "ember-cli-foundation-6-sass/components/zf-callout"], function (_exports, _zfCallout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _zfCallout.default;
    }
  });
});