define("labs-regional-viz/services/scroller", ["exports", "ember-scroll-to/services/scroller"], function (_exports, _scroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _scroller.default.extend({
    getJQueryElement: function getJQueryElement(target) {
      var jQueryElement = Ember.$(target);

      if (Ember.isEmpty(jQueryElement)) {
        Ember.Logger.warn("element couldn't be found:", target);
      }

      return jQueryElement;
    },
    scrollable: Ember.computed(function () {
      return Ember.$('#scrollable-content');
    })
  });

  _exports.default = _default;
});