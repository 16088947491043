define("labs-regional-viz/metrics-adapters/google-analytics", ["exports", "ember-metrics/metrics-adapters/google-analytics"], function (_exports, _googleAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleAnalytics.default;
    }
  });
});