define("labs-regional-viz/components/supporting-layers", ["exports", "cartobox-promises-utility/utils/carto", "labs-regional-viz/supporting-layers/rail", "labs-regional-viz/supporting-layers/aerials"], function (_exports, _carto, _rail, _aerials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    railVisible: false,
    railConfig: _rail.default,
    aerialsConfig: _aerials.default,
    railSource: null,
    aerialVisible: false,
    actions: {
      toggleRail: function toggleRail() {
        var _this = this;

        // set railSource if user is toggling rails on.
        if (!this.get('railVisible')) {
          var source = this.get('railConfig.source');

          _carto.default.getVectorTileTemplate(source['source-layers']).then(function (template) {
            return {
              id: source.id,
              type: 'vector',
              tiles: [template]
            };
          }).then(function (builtSource) {
            _this.set('railSource', builtSource);
          });
        }

        this.toggleProperty('railVisible');
      },
      toggleAerial: function toggleAerial() {
        this.toggleProperty('aerialVisible');
      }
    }
  });

  _exports.default = _default;
});