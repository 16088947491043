define("labs-regional-viz/helpers/variant-eq", ["exports", "ember-freestyle/helpers/equal"], function (_exports, _equal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(_equal.equalHelper);

  _exports.default = _default;
});