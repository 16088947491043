define("labs-regional-viz/routes/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scroller: Ember.inject.service(),
    model: function model(_ref) {
      var slug = _ref.slug;

      var _this$modelFor = this.modelFor('application'),
          _this$modelFor$pages = _this$modelFor.pages,
          pages = _this$modelFor$pages === void 0 ? [] : _this$modelFor$pages;

      return pages.findBy('slug', slug);
    },
    actions: {
      didTransition: function didTransition() {
        var scroller = this.get('scroller');
        var section = window.location.hash.substr(1); // add defensive condition for special mapbox hash,
        // which always includes slashes

        if (section && !section.includes('/')) {
          Ember.run.next(this, function () {
            scroller.scrollVertical("#".concat(section));
          });
        }
      }
    }
  });

  _exports.default = _default;
});