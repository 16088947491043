define("labs-regional-viz/router", ["exports", "labs-regional-viz/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        Ember.get(_this, 'metrics').trackPage({
          page: page,
          title: title
        });
      });
    },
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // eslint-disable-line
    this.route('page', {
      path: '/:slug'
    });
    this.route('map', {
      path: ':categorySlug/:slug'
    });
  });
  var _default = Router;
  _exports.default = _default;
});