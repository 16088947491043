define("labs-regional-viz/routes/index", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(_ref) {
      var maps = _ref.maps;

      var _maps = (0, _slicedToArray2.default)(maps, 1),
          firstMap = _maps[0];

      var categorySlug = firstMap.categorySlug,
          slug = firstMap.slug;
      this.transitionTo('map', categorySlug, slug);
    }
  });

  _exports.default = _default;
});