define("labs-regional-viz/components/zf-dropdown", ["exports", "ember-cli-foundation-6-sass/components/zf-dropdown"], function (_exports, _zfDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _zfDropdown.default;
    }
  });
});