define("labs-regional-viz/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2PhKrola",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"cell\"],[11,\"id\",\"scrollable-content\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"page-container grid-x grid-padding-x\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"cell large-10 large-offset-1 xlarge-8 xlarge-offset-2\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"model\",\"sidebar\",\"content\"]],[23,[\"model\",\"sidebar\",\"button\"]]],null]],null,{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-6 large-offset-1 xlarge-5 xlarge-offset-2\"],[9],[0,\"\\n        \"],[1,[27,\"markdown-to-html\",null,[[\"markdown\"],[[23,[\"model\",\"content\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"cell page-sidebar large-4 xlarge-3\"],[9],[0,\"\\n        \"],[1,[27,\"markdown-to-html\",null,[[\"markdown\"],[[23,[\"model\",\"sidebar\",\"content\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"sidebar\",\"button\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\"],[12,\"href\",[28,[[23,[\"model\",\"sidebar\",\"button\"]]]]],[11,\"class\",\"button\"],[9],[1,[27,\"if\",[[23,[\"model\",\"sidebar\",\"buttontext\"]],[23,[\"model\",\"sidebar\",\"buttontext\"]],[23,[\"model\",\"sidebar\",\"button\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-10 large-offset-1 xlarge-8 xlarge-offset-2\"],[9],[0,\"\\n        \"],[1,[27,\"markdown-to-html\",null,[[\"markdown\"],[[23,[\"model\",\"content\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-regional-viz/templates/page.hbs"
    }
  });

  _exports.default = _default;
});