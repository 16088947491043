define("labs-regional-viz/utils/build-mapbox-paint-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildPaint;

  function buildPaint() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$colors = _ref.colors,
        colors = _ref$colors === void 0 ? [] : _ref$colors,
        _ref$breaks = _ref.breaks,
        breaks = _ref$breaks === void 0 ? [] : _ref$breaks,
        _ref$opacity = _ref.opacity,
        opacity = _ref$opacity === void 0 ? [] : _ref$opacity;

    var paint = {
      'fill-color': ['step', ['get', 'value']],
      'fill-opacity': opacity
    };
    var colorArray = paint['fill-color']; // there will always be 1 more color than breaks

    colorArray.push(colors[0]);
    breaks.forEach(function (color, i) {
      colorArray.push(breaks[i]);
      colorArray.push(colors[i + 1]);
    });
    return paint;
  }
});