define("labs-regional-viz/routes/application", ["exports", "fetch", "labs-regional-viz/utils/structure-and-order-cms"], function (_exports, _fetch, _structureAndOrderCms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return (0, _fetch.default)('/assets/cms/content.json').then(function (blob) {
        return blob.json();
      }).then(function (json) {
        return (0, _structureAndOrderCms.default)(json);
      });
    }
  });

  _exports.default = _default;
});