define("labs-regional-viz/components/freestyle-palette", ["exports", "ember-freestyle/components/freestyle-palette"], function (_exports, _freestylePalette) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestylePalette.default;
    }
  });
});