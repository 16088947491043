define("labs-regional-viz/templates/components/freestyle-palette-item", ["exports", "ember-freestyle/templates/components/freestyle-palette-item"], function (_exports, _freestylePaletteItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestylePaletteItem.default;
    }
  });
});