define("labs-regional-viz/routes/map", ["exports", "cartobox-promises-utility/utils/carto", "labs-regional-viz/utils/build-mapbox-paint-object"], function (_exports, _carto, _buildMapboxPaintObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = Ember.RSVP.Promise;

  var _default = Ember.Route.extend({
    scroller: Ember.inject.service(),
    model: function model(_ref) {
      var slug = _ref.slug;

      var _this$modelFor = this.modelFor('application'),
          _this$modelFor$maps = _this$modelFor.maps,
          maps = _this$modelFor$maps === void 0 ? [] : _this$modelFor$maps;

      var mapNarrative = maps.findBy('slug', slug); // find the first map config because the
      // design doesn't support multiple

      var mapConfig = mapNarrative.map;
      var sources = mapConfig.sources;
      var cartoSourcePromises = Object.keys(sources || {}).map(function (key) {
        var source = sources[key];
        var type = source.type;
        var _source$minzoom = source.minzoom,
            minzoom = _source$minzoom === void 0 ? 0 : _source$minzoom; // resolve sources with promises first

        if (type === 'cartovector') {
          return _carto.default.getVectorTileTemplate(source['source-layers']).then(function (template) {
            return {
              id: source.id,
              type: 'vector',
              tiles: [template],
              minzoom: minzoom
            };
          });
        }

        return source;
      });
      return Promise.all(cartoSourcePromises).then(function (cartoPromises) {
        Ember.set(mapConfig, 'sources', cartoPromises);
        return mapNarrative;
      }).then(function (enrichedMapNarrative) {
        var layerGroups = enrichedMapNarrative.map.layerGroups;
        var builtLayerGroups = Ember.copy(layerGroups.map(function (layerGroup) {
          return {
            id: layerGroup.id,
            layers: []
          };
        }), true);
        layerGroups.forEach(function (_ref2, i) {
          var layers = _ref2.layers;
          layers.forEach(function (layer) {
            if (layer.type === 'choropleth') {
              var id = layer.id,
                  source = layer.source,
                  paintConfig = layer.paintConfig;
              builtLayerGroups[i].layers.push({
                id: id,
                type: 'fill',
                source: source,
                'source-layer': layer['source-layer'],
                paint: (0, _buildMapboxPaintObject.default)(paintConfig)
              }); // for choropleth fill layers, push an outlines line layer as well

              builtLayerGroups[i].layers.push({
                id: "".concat(id, "-line"),
                type: 'line',
                source: source,
                'source-layer': layer['source-layer'],
                paint: {
                  'line-color': 'rgba(131, 131, 131, 1)',
                  'line-width': 0.5
                }
              });
            } else {
              // no building necessary if not type choropleth
              builtLayerGroups[i].layers.push(layer);
            }
          });
        });
        Ember.set(enrichedMapNarrative, 'map.mapboxLayers', builtLayerGroups); // set(enrichedMapNarrative, 'map.legends', layers);

        return enrichedMapNarrative;
      });
    },
    actions: {
      didTransition: function didTransition() {
        var scroller = this.get('scroller');
        Ember.run.next(this, function () {
          scroller.scrollVertical('#top', {
            duration: 400
          });
        });
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});