define("labs-regional-viz/components/mapbox-gl-control", ["exports", "ember-mapbox-gl/components/mapbox-gl-control"], function (_exports, _mapboxGlControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mapboxGlControl.default;
    }
  });
});